import React, { useEffect } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { arrayUnion, collection, CollectionReference, doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { observer } from "mobx-react";
import { logDebug } from "../logger";
import { Tooltip } from "react-tooltip";
interface KeywordStat {
	keyword: string;
	topRankedUrl: string;
	rank?: number;
	rankChange?: number;
	searchVolume?: number;
	keywordDifficulty?: number;
	broadCostPerClick?: number;
	phraseCostPerClick?: number;
	exactCostPerClick?: number;
	seoClicks?: number;
	seoClicksChange?: number;
	totalMonthlyClicks?: number;
	percentMobileSearches?: number;
	percentDesktopSearches?: number;
	percentNotClicked?: number;
	percentPaidClicks?: number;
	percentOrganicClicks?: number;
	broadMonthlyCost?: number;
	phraseMonthlyCost?: number;
	exactMonthlyCost?: number;
	paidCompetitors?: number;
	rankingHomepages?: number;
}

const KeywordStatKeysMapForZip: { [k: string]: string } = {
	keyword: "k",
	topRankedUrl: "t",
	rank: "r",
	rankChange: "rc",
	searchVolume: "sv",
	keywordDifficulty: "kd",
	broadCostPerClick: "bcc",
	phraseCostPerClick: "pcc",
	exactCostPerClick: "ecc",
	seoClicks: "sc",
	seoClicksChange: "scc",
	totalMonthlyClicks: "tmc",
	percentMobileSearches: "pms",
	percentDesktopSearches: "pds",
	percentNotClicked: "pnc",
	percentPaidClicks: "ppc",
	percentOrganicClicks: "poc",
	broadMonthlyCost: "bmc",
	phraseMonthlyCost: "pmc",
	exactMonthlyCost: "emc",
	paidCompetitors: "pc",
	rankingHomepages: "rh",
};
const KeywordStatKeysMapForZipReverse: { [k: string]: string } = {};
for (const key in KeywordStatKeysMapForZip) {
	KeywordStatKeysMapForZipReverse[KeywordStatKeysMapForZip[key]] = key;
}
interface ZippedKeywordStat {
	k?: string;
	t?: string;
	r?: number;
	rc?: number;
	sv?: number;
	kd?: number;
	bcc?: number;
	pcc?: number;
	ecc?: number;
	sc?: number;
	scc?: number;
	tmc?: number;
	pms?: number;
	pds?: number;
	pnc?: number;
	ppc?: number;
	poc?: number;
	bmc?: number;
	pmc?: number;
	emc?: number;
	pc?: number;
	rh?: number;
}

interface KeywordStats {
	results: KeywordStat[];
}
type ZippedKeywordStats = ZippedKeywordStat[];

function unzipKeywordStat(zipped: ZippedKeywordStat): KeywordStat {
	const keywordStat: KeywordStat = {} as KeywordStat;
	for (const key in zipped) {
		// @ts-ignore
		const value = zipped[key];
		const newKey = KeywordStatKeysMapForZipReverse[key];
		if (newKey) {
			// @ts-ignore
			keywordStat[newKey] = value;
		}
	}
	return keywordStat;
}
function unzipKeywordStats(zipped: ZippedKeywordStats): KeywordStat[] {
	const keywordStats: KeywordStats = { results: [] };
	for (const zippedStat of zipped) {
		keywordStats.results.push(unzipKeywordStat(zippedStat));
	}
	return keywordStats.results;
}

interface ZippedSpyfuData {
	id: string;
	spyfuData: {
		url: string;
		domainStats: {
			domain: string;
			results: {}[];
			resultsCount: number;
		};
		keywordStats: ZippedKeywordStats;
	}[];
}
interface SpyfuData {
	id: string;
	url: string;
	domainStats: {
		domain: string;
		results: {
			averageAdRank: number;
			averageOrganicRank: number;
			monthlyBudget: number;
			monthlyOrganicClicks: number;
			monthlyOrganicValue: number;
			monthlyPaidClicks: number;
			searchMonth: number;
			searchYear: number;
			strength: number;
			totalAdsPurchased: number;
			totalInverseRank: number;
			totalOrganicResults: number;
		}[];
		resultsCount: number;
	};
	keywordStats: KeywordStat[];
}

const SpyfuPage: React.FC = observer(() => {
	// const [ignoredUrls, setIgnoredUrls] = React.useState<string[]>([]);
	const [allSpyfuData, setAllSpyfuData] = React.useState<{ id: string; data: SpyfuData[] }[]>([]);
	const [latestSpyfuData, setLatestSpyfuData] = React.useState<{ id: string; data: SpyfuData[] }>();
	const [selectedSpyfuData, setSelectedSpyfuData] = React.useState<SpyfuData | null>(null);
	const firestoreToolsCollectionRef = collection(useFirestore(), `spyfu`) as CollectionReference<any>;
	const dataFromDataBase = useFirestoreCollectionData<any>(firestoreToolsCollectionRef, { idField: "id" });
	const nonDataIds = ["Latest", "Ignore"];

	useEffect(() => {
		if (dataFromDataBase.status === "success") {
			const ignore = dataFromDataBase.data.find((data: any) => data.id === "Ignore");
			const _ignoredUrls = (ignore?.ignoredUrls || []).map((url: string) => url.toLowerCase());
			// setIgnoredUrls(_ignoredUrls);

			const dataOnly = dataFromDataBase.data.filter((data: any) => !nonDataIds.includes(data.id)) as ZippedSpyfuData[];
			const unZipped = dataOnly.map((zipped: ZippedSpyfuData) => {
				const data = zipped.spyfuData
					.map((spyfuData) => {
						return {
							url: spyfuData.url,
							domainStats: spyfuData.domainStats,
							keywordStats: unzipKeywordStats(spyfuData.keywordStats),
						} as SpyfuData;
					})
					.filter((spyfuData) => !_ignoredUrls.includes(spyfuData.url.toLowerCase()));
				return {
					id: zipped.id,
					data,
				};
			});
			setAllSpyfuData(unZipped);

			const latest = dataFromDataBase.data.find((data: any) => data.id === "Latest");
			const latestKey = latest.latestDataPath.split("/").pop();
			const latestData = unZipped.find((data) => data.id === latestKey);
			_setLatestSpyfuData({
				data: latestData?.data || [],
				id: latestKey || "",
			});
		}
	}, [dataFromDataBase.status, dataFromDataBase.data]);

	const _setSelectedSpyfuData = (data: SpyfuData) => {
		const dataClone = JSON.parse(JSON.stringify(data)) as SpyfuData;
		dataClone.keywordStats = dataClone.keywordStats
			.filter((keywordStat) => !!keywordStat.seoClicks)
			.sort((a, b) => (b.seoClicks || 0) - (a.seoClicks || 0));
		setSelectedSpyfuData(dataClone);
	};

	const _setLatestSpyfuData = (data: { data: SpyfuData[]; id: string }) => {
		const dataClone = JSON.parse(JSON.stringify(data)) as { data: SpyfuData[]; id: string };
		dataClone.data.sort((a, b) => {
			const aTotal = a.keywordStats.reduce((acc, curr) => acc + (curr.seoClicks || 0), 0);
			const bTotal = b.keywordStats.reduce((acc, curr) => acc + (curr.seoClicks || 0), 0);
			return bTotal - aTotal;
		});
		// // remove duplicates
		dataClone.data = dataClone.data.filter((value, index, self) => {
			return self.findIndex((t) => new URL(t.url).host === new URL(value.url).host) === index;
		});
		setLatestSpyfuData(dataClone);
	};

	if (dataFromDataBase.status === "loading") {
		return <div>Loading...</div>;
	}

	async function ignoreURL(url: string) {
		const docRef = doc(getFirestore(), "spyfu", "Ignore");

		// Check if the document exists
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			// Update if it exists
			await updateDoc(docRef, {
				ignoredUrls: arrayUnion(url),
			});
		} else {
			// Create with initial array if it doesn’t exist
			await setDoc(docRef, {
				ignoredUrls: [url],
			});
		}
	}

	function getAccumulatedData(keywordStats: KeywordStat[], key: string): number {
		return parseInt(
			keywordStats
				// @ts-ignore
				.reduce((acc, curr) => acc + (curr[key] || 0), 0)
				.toFixed(0)
		);
	}
	function getAverageData(keywordStats: KeywordStat[], key: string): string {
		return [
			keywordStats.reduce(
				(acc, curr) => {
					// @ts-ignore
					acc.acc += curr[key] || 0;
					// @ts-ignore
					acc.totalNonZero += curr[key] ? 1 : 0;
					return acc;
				},
				{ acc: 0, totalNonZero: 0 }
			),
		]
			.map(({ acc, totalNonZero }) => (totalNonZero ? acc / totalNonZero : 0))[0]
			.toFixed(2);
	}

	return (
		<div className="flex w-full justify-center">
			<div className="flex flex-col w-[90%] justify-center">
				{!selectedSpyfuData && (
					<div className="mt-4">
						<div className="flex w-full justify-start mb-4">
							<div className="flex">
								{[...allSpyfuData].reverse().map((data, index) => {
									return (
										<div
											key={data.id}
											className={`cursor-pointer hover:text-blue-500 mx-2 p-1 ${
												latestSpyfuData?.id === data.id ? "bg-gray-300 rounded-xl" : ""
											}`}
											onClick={() => {
												_setLatestSpyfuData({ data: data.data, id: data.id });
											}}
										>
											{[data.id].map((id) => {
												const sp = id.replace("SPYFU_", "").split("_");
												return `${sp[1]}-${sp[2]}-${sp[0]}`;
											})}
										</div>
									);
								})}
							</div>
						</div>
						<div className="h-16"></div>
						<Tooltip anchorSelect=".tseo" place="top">
							Estimation of the amount of organic SEO with the addition of 35% paid clicks
						</Tooltip>
						<Tooltip anchorSelect=".emr" place="top">
							Estimation of revenue based on the number of organic clicks,<br></br> an industry conversion to deals benchmark
							of 8%, and an average of $50 per unit
						</Tooltip>
						<Tooltip anchorSelect=".eyr" place="top">
							Monthly revenue multiplied by 12 months to represent a year{" "}
						</Tooltip>
						<Tooltip anchorSelect=".emra" place="top">
							Total Clicks multiplied by 8% conversion and $50 average price point
						</Tooltip>
						<Tooltip anchorSelect=".eyra" place="top">
							Monthly revenue multiplied by 12 months to represent a year
						</Tooltip>

						<table>
							<thead>
								<tr>
									<th>Store</th>
									<th className="tseo">Total Clicks </th>
									{/* <th>Broad Cost Per Click Average</th> */}
									{/* <th className="emr">Estimated Monthly Revenue </th>
									<th className="eyr">Estimated Yearly Revenue</th> */}

									<th className="emra">Estimated Monthly Revenue </th>
									<th className="eyra">Estimated Yearly Revenue </th>
									{/* <th>Percent Organic Clicks Average</th> */}
									{/* <th>Percent Paid Clicks Average</th> */}
									{/* <th>Total Monthly Clicks</th> */}
									<th>Remove Store from the list</th>
								</tr>
							</thead>
							<tbody>
								{latestSpyfuData?.data.map((data, index) => {
									return (
										<tr className={`p-2 ${index % 2 ? "bg-gray-200" : "bg-gray-100"}`} key={index}>
											<td
												className="cursor-pointer hover:text-blue-500"
												onClick={(e) => {
													if (e.ctrlKey || e.metaKey || e.shiftKey) {
														window.open(data.url, "_blank");
													} else {
														_setSelectedSpyfuData(data);
													}
												}}
											>
												{new URL(data.url).host}
											</td>
											<td className="text-right">
												{Math.floor(getAccumulatedData(data.keywordStats, "seoClicks") * 1.35).toLocaleString()}
											</td>
											{/* <td className="text-right">
												$
												{(
													parseInt(getAccumulatedData(data.keywordStats, "seoClicks").replaceAll(",", "")) *
													0.08 *
													50
												).toLocaleString()}
											</td>
											<td className="text-right">
												$
												{(
													parseInt(getAccumulatedData(data.keywordStats, "seoClicks").replaceAll(",", "")) *
													0.08 *
													50 *
													12
												).toLocaleString()}
											</td> */}
											<td className="text-right">
												$
												{Math.floor(
													getAccumulatedData(data.keywordStats, "seoClicks") * 0.08 * 50 * 1.35
												).toLocaleString()}
											</td>
											<td className="text-right">
												$
												{Math.floor(
													getAccumulatedData(data.keywordStats, "seoClicks") * 0.08 * 50 * 1.35 * 12
												).toLocaleString()}
											</td>
											{/* <td>{getAverageData(data.keywordStats, "broadCostPerClick")}</td> */}
											{/* <td className="text-right">${getAccumulatedData(data.keywordStats, "broadMonthlyCost")}</td> */}
											{/* <td>{getAverageData(data.keywordStats, "percentOrganicClicks")}</td> */}
											{/* <td>{getAverageData(data.keywordStats, "percentPaidClicks")}</td> */}
											{/* <td className="text-right">{getAccumulatedData(data.keywordStats, "totalMonthlyClicks")}</td> */}
											<td
												className="cursor-pointer hover:text-red-500 text-center align-middle"
												onClick={() => ignoreURL(data.url)}
											>
												Remove
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
				{selectedSpyfuData && (
					<div className="mt-4">
						<div className="flex justify-between p-2 bg-gray-200">
							<div className="cursor-pointer hover:text-blue-500" onClick={() => setSelectedSpyfuData(null)}>
								Back
							</div>
							<div className="flex">
								{[...allSpyfuData].reverse().map((data, index) => {
									return (
										<div
											key={data.id}
											className={`cursor-pointer hover:text-blue-500 mx-2 p-1 ${
												latestSpyfuData?.id === data.id ? "bg-gray-300 rounded-xl" : ""
											}`}
											onClick={() => {
												_setLatestSpyfuData({ data: data.data, id: data.id });
												const selected = data.data.find((d) => d.url === selectedSpyfuData.url);
												if (selected) {
													_setSelectedSpyfuData(selected);
												}
											}}
										>
											{[data.id].map((id) => {
												const sp = id.replace("SPYFU_", "").split("_");
												return `${sp[1]}-${sp[2]}-${sp[0]}`;
											})}
										</div>
									);
								})}
							</div>
							<div className="cursor-pointer  hover:text-red-500" onClick={() => ignoreURL(selectedSpyfuData.url)}>
								Remove
							</div>
						</div>
						<div className="flex w-full ">
							<div className=" bg-gray-100 p-4 my-4">
								<Tooltip anchorSelect=".box1" place="top">
									The total amount allocated for marketing and advertising expenses over a month.
								</Tooltip>
								<Tooltip anchorSelect=".box2" place="top">
									The total number of clicks a store receives from organic search results in a month.
								</Tooltip>
								<Tooltip anchorSelect=".box3" place="top">
									The estimated monetary worth of a store’s organic traffic in a month based on equivalent paid ad costs.
								</Tooltip>
								<Tooltip anchorSelect=".box4" place="top">
									The total number of clicks a store receives from paid advertisements in a month.
								</Tooltip>
								<Tooltip anchorSelect=".box5" place="top">
									DR Site ranking based on SEO MOZ benchmark score.
								</Tooltip>
								<Tooltip anchorSelect=".box6" place="top">
									The total number of advertisements bought during a month.
								</Tooltip>
								<Tooltip anchorSelect=".box7" place="top">
									The sum of the reciprocals of ranks, often used to measure relevance or ranking performance in search
									results.
								</Tooltip>
								<Tooltip anchorSelect=".box8" place="top">
									The total number of non-paid search results displayed for the brand/keywords.
								</Tooltip>

								{/* <div>Results Count: {selectedSpyfuData.domainStats.resultsCount}</div> */}
								<div className="flex w-full justify-center mb-1">
									<b>{selectedSpyfuData.domainStats.domain}</b>
								</div>
								<div>Domain statistics regardless of Olaplex keywords:</div>

								<br></br>
								<div className="box1">
									Monthly Budget: ${selectedSpyfuData.domainStats.results[0].monthlyBudget.toLocaleString()}
								</div>
								<div className="box2">
									Monthly Organic Clicks: {selectedSpyfuData.domainStats.results[0].monthlyOrganicClicks.toLocaleString()}
								</div>
								<div className="box3">
									Monthly Organic Value: {selectedSpyfuData.domainStats.results[0].monthlyOrganicValue.toLocaleString()}
								</div>
								<div className="box4">
									Monthly Paid Clicks: {selectedSpyfuData.domainStats.results[0].monthlyPaidClicks.toLocaleString()}
								</div>
								<div className="box5">Strength: {selectedSpyfuData.domainStats.results[0].strength}</div>
								<div className="box6">
									Total Ads Purchased: {selectedSpyfuData.domainStats.results[0].totalAdsPurchased.toLocaleString()}
								</div>
								<div className="box7">
									Total Inverse Rank: {selectedSpyfuData.domainStats.results[0].totalInverseRank.toLocaleString()}
								</div>
								<div className="box8">
									Total Organic Results: {selectedSpyfuData.domainStats.results[0].totalOrganicResults.toLocaleString()}
								</div>
								<div>website traffic: coming soon</div>
							</div>
						</div>
						<div className="flex justify-between flex-col p-2 bg-gray-100">
							<Tooltip anchorSelect=".table1" place="top">
								Estimation of the amount of organic SEO and paid clicks for the last 30 days
							</Tooltip>
							<Tooltip anchorSelect=".table2" place="top">
								Average cost an advertiser pays for clicks in campaigns targeting a wide, non-specific audience or
								broad-match keywords.
							</Tooltip>
							<Tooltip anchorSelect=".table3" place="top">
								The total estimated cost of running broad-targeted advertising campaigns for a month.
							</Tooltip>
							<Tooltip anchorSelect=".table4" place="top">
								The percentage of total clicks a store receives from organic search results compared to all traffic sources.
							</Tooltip>
							<Tooltip anchorSelect=".table5" place="top">
								The percentage of total clicks a store receives from paid advertisements compared to all traffic sources.
							</Tooltip>

							<div>Keyword Stats</div>
							<table>
								<thead>
									<tr>
										<th>Keyword</th>
										<th className="table1">Clicks</th>
										<th className="table2">Broad Cost Per Click</th>
										<th className="table3">Broad Monthly Cost</th>
										<th className="table4">Percent Organic Clicks</th>
										<th className="table5">Percent Paid Clicks</th>
										{/* <th>Monthly SEO Clicks Change</th> */}
										{/* <th>Total Monthly Clicks</th> */}
									</tr>
								</thead>
								<tbody>
									{
										<tr className={`bg-gray-200 py-2`}>
											<td>Accumulated Data</td>
											<td className="text-right">
												T:{" "}
												{Math.floor(
													getAccumulatedData(selectedSpyfuData.keywordStats, "seoClicks") * 1.35
												).toLocaleString()}
											</td>
											<td className="text-right">
												AVG: ${getAverageData(selectedSpyfuData.keywordStats, "broadCostPerClick")}
											</td>
											<td className="text-right">
												T: ${getAccumulatedData(selectedSpyfuData.keywordStats, "broadMonthlyCost")}
											</td>
											<td className="text-right">
												AVG:{" "}
												{(
													parseFloat(getAverageData(selectedSpyfuData.keywordStats, "percentOrganicClicks")) * 100
												).toFixed(0)}
												%
											</td>
											<td className="text-right">
												AVG:{" "}
												{(
													parseFloat(getAverageData(selectedSpyfuData.keywordStats, "percentPaidClicks")) * 100
												).toFixed(0)}
												%
											</td>
											{/* <td className="text-right text-gray-400">NA</td> */}
											{/* <td className="text-right">
												T: {getAccumulatedData(selectedSpyfuData.keywordStats, "totalMonthlyClicks")}
											</td> */}
										</tr>
									}
									{selectedSpyfuData.keywordStats.map((keywordStat, index) => {
										return (
											<tr key={index} className={`${index % 2 ? "bg-gray-200 py-2" : "bg-gray-100"}`}>
												<td>{keywordStat.keyword}</td>
												<td className="text-right">
													{Math.floor((keywordStat.seoClicks || 0) * 1.35).toLocaleString()}
												</td>
												<td className={`text-right ${!keywordStat.broadCostPerClick && "text-gray-400"}`}>
													{keywordStat.broadCostPerClick && "$"}
													{keywordStat.broadCostPerClick || "NA"}
												</td>
												<td className={`text-right ${!keywordStat.broadMonthlyCost && "text-gray-400"}`}>
													{keywordStat.broadMonthlyCost
														? `$${Math.floor(keywordStat.broadMonthlyCost || 0)?.toLocaleString()}`
														: "NA"}
												</td>
												<td className={`text-right ${!keywordStat.percentOrganicClicks && "text-gray-400"}`}>
													{keywordStat.percentOrganicClicks
														? (keywordStat.percentOrganicClicks * 100).toFixed(0) + "%"
														: "NA"}
												</td>
												<td className={`text-right ${!keywordStat.percentPaidClicks && "text-gray-400"}`}>
													{keywordStat.percentPaidClicks
														? (keywordStat.percentPaidClicks * 100).toFixed(0) + "%"
														: "NA"}
												</td>
												{/* <td className={`text-right ${!keywordStat.seoClicksChange && "text-gray-400"}`}>
													{keywordStat.seoClicksChange || "NA"}
												</td> */}
												{/* <td className={`text-right ${!keywordStat.totalMonthlyClicks && "text-gray-400"}`}>
													{keywordStat.totalMonthlyClicks?.toLocaleString()}
												</td> */}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</div>
		</div>
	);
});

export default SpyfuPage;
