import React from "react";
import { allWallmarkDays } from "../generatedData";
import { logDebug } from "../logger";
// import _wallmarkData from '../data/wallmart.json';
interface IHistory {
	price: number;
	sell: number;
	total: number;
	month: number;
}
interface IWalmartOutputPerProduct {
	name: string;
	history: IHistory[];
	listOfPrices: number[];
	listOfSales: number[];
	oneTimeSell: number;
	oneTimePrice: number;
	oneTimeTotal: number;
}

const walmarkData: IWalmartOutputPerProduct[] = allWallmarkDays[0] as any;
const dates = ["August 2024", "September 2024", "October 2024", "November 2024", "December 2024"];
const WalmartTablePage: React.FC<{}> = ({}) => {
	console.log("walmarkData", walmarkData[0].history);
	const historyForMapping = Array(3).fill(0);
	const historyTotals: number[] = [];
	historyTotals[0] = walmarkData
		.map((item) => {
			if (item.history.length > 0 && item.history[0].sell > 0 && item.history[0].price > 0) {
				if (item.history[0].price > 1000) {
					item.history[0].price /= 100;
				}
				return item.history[0].sell * item.history[0].price;
			}
			return 0;
		})
		.reduce((acc, val) => acc + val, 0);
	historyTotals[1] = walmarkData
		.map((item) => {
			if (item.history.length > 1 && item.history[1].sell > 0 && item.history[1].price > 0) {
				if (item.history[0].price > 1000) {
					item.history[0].price /= 100;
				}
				return item.history[1].sell * item.history[1].price;
			}
			return 0;
		})
		.reduce((acc, val) => acc + val, 0);
	historyTotals[2] = walmarkData
		.map((item) => {
			if (item.history.length > 2 && item.history[2].sell > 0 && item.history[2].price > 0) {
				// logDebug("item.history[2].price", item.history[2].price, typeof item.history[2].price);
				if (item.history[2].price > 1000) {
					logDebug("!!!!!!item.history[2].price", item.history[2].price);
					item.history[2].price /= 100;
				}
				return item.history[2].sell * item.history[2].price;
			}
			return 0;
		})
		.reduce((acc, val) => acc + val, 0);

	return (
		<div className="m-4">
			<table className="border">
				<thead>
					<tr>
						<th></th>
						{historyForMapping.map((month, j) => {
							return (
								<th key={`month_${j}`} colSpan={3}>
									{dates[j]}
								</th>
							);
						})}
					</tr>

					<tr>
						<th>Title</th>
						{historyForMapping.map((month, j) => {
							return (
								<React.Fragment key={`months_${j}`}>
									<th>Sold</th>
									<th>Price</th>
									<th>Total</th>
								</React.Fragment>
							);
						})}
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div style={{ fontWeight: "bolder" }} className="w-[600px] " />
							<div style={{ fontWeight: "bolder" }} className="flex justify-center items-center">
								Grand Total
							</div>
						</td>
						{historyTotals.map((total, j) => {
							return (
								<React.Fragment key={`total_${j}`}>
									<td>
										<div className="w-16 flex justify-center" />
									</td>
									<td>
										<div className="w-16 flex justify-center" />
									</td>
									<td>
										<div style={{ fontWeight: "bolder" }} className="font-bold w-16 flex justify-center text-sm ">
											${Math.floor(total).toLocaleString()}
										</div>
									</td>
								</React.Fragment>
							);
						})}
					</tr>
					{walmarkData.map((item, i) => {
						const dt = historyForMapping.length - item.history.length;
						for (let j = 0; j < dt; j++) {
							item.history.unshift({ price: 0, sell: 0, total: 0, month: j });
						}
						if (!item.name.toLowerCase().includes("olaplex")) {
							return null;
						}
						return (
							<tr>
								<td>
									<div
										className={`w-[600px] flex justify-start ${
											item.name.toLowerCase().includes("liter") ? "text-red-500" : ""
										}`}
									>
										{item.name}
									</div>
								</td>
								{item.history.map((history, j) => {
									if (j >= historyForMapping.length) {
										return null;
									}
									return (
										<React.Fragment key={`history_${j}`}>
											<td>
												<div className="w-16 flex justify-center">{history.sell.toLocaleString()}</div>
											</td>
											<td>
												<div className="w-16 flex justify-center">${history.price.toLocaleString()}</div>
											</td>
											<td>
												<div style={{ fontWeight: "bolder" }} className="w-16 flex justify-center">
													${Math.round(history.price * history.sell).toLocaleString()}
												</div>
											</td>
										</React.Fragment>
									);
								})}
							</tr>
						);
					})}
					<tr>
						<td>
							<div style={{ fontWeight: "bolder" }} className="w-[600px] " />
							<div style={{ fontWeight: "bolder" }} className="flex justify-center items-center">
								Grand Total
							</div>
						</td>
						{historyTotals.map((total, j) => {
							return (
								<React.Fragment key={`total_${j}`}>
									<td>
										<div className="w-16 flex justify-center" />
									</td>
									<td>
										<div className="w-16 flex justify-center" />
									</td>
									<td>
										<div style={{ fontWeight: "bolder" }} className="font-bold w-16 flex justify-center text-sm ">
											${total.toLocaleString()}
										</div>
									</td>
								</React.Fragment>
							);
						})}
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default WalmartTablePage;
