import React, { useRef } from "react";
import "./App.css";
import EbayTablePage from "./pages/EbayTablePage";
import WalmartTablePage from "./pages/WallmartTablePage";
import { FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { getFirestore } from "firebase/firestore";
import { observer } from "mobx-react";
import { firebaseConfig } from "./FirebaseApp";
import SpyfuPage from "./pages/SpyfuPage";

const App: React.FC = observer(() => {
	const firestore = useRef(getFirestore(useFirebaseApp()));
	const firestoreInstance = firestore.current;
	const getPage = () => {
		if (location.href.includes("walmart")) {
			return <WalmartTablePage />;
		}
		if (location.href.includes("ebay")) {
			return <EbayTablePage />;
		}
		if (location.href.includes("stores")) {
			return <SpyfuPage />;
		}
		return <SpyfuPage />;
	};
	return (
		<FirestoreProvider sdk={firestoreInstance}>
			<div className="w-full">
				<div className="flex w-full justify-center">
					<a href="/stores" className="m-2">
						Stores
					</a>
					<a href="/walmart" className="m-2">
						Walmart
					</a>
					<a href="/ebay" className="m-2">
						Ebay
					</a>
				</div>
				{getPage()}
			</div>
		</FirestoreProvider>
	);
});

const FirebaseApp: React.FC = () => {
	return (
		<FirebaseAppProvider firebaseConfig={firebaseConfig}>
			<App />
		</FirebaseAppProvider>
	);
};

export default FirebaseApp;
