import React, { useEffect, useState } from "react";
// Extend the Math interface
declare global {
	interface Math {
		sum(numbers: number[]): number;
	}
}

// Implement the method
Math.sum = function (numbers: number[]): number {
	return numbers.reduce((acc, current) => acc + current, 0);
};

interface EBayData {
	title: string;
	price: string;
	sold: string;
	image: string;
	seller: string;
	pageLink: string;
	total?: number;
	// "title": "Shop on eBay",
	// "price": "$20.00",
	// "sold": "no sold info",
	// "image": "https://ir.ebaystatic.com/rs/v/fxxj3ttftm5ltcqnto1o4baovyl.png",
	// "seller": "no seller",
	// "pageLink": "https://ebay.com/itm/123456?itmmeta=012DEW30YG0MEEKND7NH&hash=item123546:g:acwAA9KNiJowH:sc:ShippingMethodStandard!95008!US!-1&itmprp=enc%3AbgepL1tlUHjMGCVfSTGJh%2BzsVKeJ3CQk7NizDI4BZeppuFnmyS6Ijyp8lh%2FnEw%2BWqO7uTV1Q6izE1R0T54aV8j71F4xlWfVcGft4%2FiOQhtqVXA1rW6M1atPARQRmhqUxtEPJKhKtSFgI%2Bvwlzb0GwVCtkp%3ABlBMUObkmabpYw"
}

interface SellDataPerDay {
	sold: number;
	price: number;
	total: number;
	soldDt: number;
	totalDt: number;
}

interface ItemPerDayData {
	title: string;
	days: SellDataPerDay[];
}
interface SellerData {
	seller: string;
	items: ItemPerDayData[];
	totalPerDay: number[];
}

import { allEbayDays } from "../generatedData";

const allDays: EBayData[][] = allEbayDays;
const dates = ["8-13-2024", "8-15-2024", "8-19-2024", "8-23-2024", "8-27-2024"];
const EbayTablePage: React.FC<{}> = ({}) => {
	function createSellDeltas(days: SellDataPerDay[]): SellDataPerDay[] {
		let firstNonZero = days.find((day) => day.sold !== 0);
		// const lastNonZero = [...days].reverse().find(day => day.sold !== 0);
		if (!firstNonZero) {
			console.error("no non zero days");
			return days;
		}
		// first fix missing days with colsest non zero day
		for (let i = 0; i < days.length; i++) {
			if (days[i].sold === 0) {
				days[i] = { ...firstNonZero };
			} else {
				firstNonZero = days[i];
			}
		}

		// calculate deltas
		for (let i = 1; i < days.length; i++) {
			const prev = days[i - 1];
			const cur = days[i];
			let curSoldDt = cur.sold - prev.sold;
			if (curSoldDt < 0) {
				curSoldDt = cur.sold;
			}
			days[i] = { ...cur, soldDt: curSoldDt, totalDt: curSoldDt * cur.price };
		}

		return days;
	}

	function removeDuplicates(allWithSold: EBayData[]): EBayData[] {
		const map = new Map<string, EBayData>();
		for (const item of allWithSold) {
			const key = item.title + item.price + item.seller + item.image;
			if (!map.has(key)) {
				map.set(key, item);
			}
		}
		return Array.from(map.values()).sort(
			(a, b) => parseInt(b.sold.replace(",", "").split(" ")[0]) - parseInt(a.sold.replace(",", "").split(" ")[0])
		);
	}

	const [sellersData, setSellersData] = useState<SellerData[]>([]);
	const [sellerMapArr, setSellerMapArr] = useState<Map<string, { items: EBayData[]; total: number }>[]>([]);
	useEffect(() => {
		const _sellersData: SellerData[] = [];
		const _sellerMapArr: Map<string, { items: EBayData[]; total: number }>[] = [];
		allDays.forEach((day, dayIndex) => {
			const sellerMap = new Map<string, { items: EBayData[]; total: number }>();
			let allWithSold = day.filter((item) => item.sold !== "no sold info" && item.seller !== "no seller");
			allWithSold = removeDuplicates(allWithSold);
			for (const item of allWithSold) {
				const seller = item.seller.split(" ")[0];
				let sellerData: SellerData | undefined = _sellersData.find((sellerData) => sellerData.seller === seller);
				if (!sellerData) {
					sellerData = { seller, items: [], totalPerDay: Array(allDays.length).fill(0) };
					_sellersData.push(sellerData);
				}
				let itemPerDayData: ItemPerDayData | undefined = sellerData.items.find(
					(itemPerDayData) => itemPerDayData.title.toLowerCase() === item.title.toLowerCase()
				);
				if (!itemPerDayData) {
					itemPerDayData = { title: item.title, days: Array(allDays.length).fill({ sold: 0, price: 0, total: 0 }) };
					sellerData.items.push(itemPerDayData);
				}

				if (!sellerMap.has(seller)) {
					sellerMap.set(seller, { items: [], total: 0 });
				}
				const price0 = parseFloat(item.price.replace("$", "").replaceAll(",", ""));
				const numSold0 = parseInt(item.sold.replaceAll(",", "").split(" ")[0]);

				sellerMap.get(seller)!.items.push({ ...item, total: Math.floor(price0 * numSold0) });
				itemPerDayData.days[dayIndex] = {
					sold: numSold0,
					price: price0,
					total: Math.floor(price0 * numSold0),
					soldDt: 0,
					totalDt: 0,
				};
			}
			Array.from(sellerMap.entries()).forEach(([seller, sellerData]) => {
				const total = sellerData.items.reduce((prev, cur) => {
					return prev + cur.total!;
				}, 0);
				sellerMap.set(seller, { items: sellerData.items, total });
			});
			_sellerMapArr.push(sellerMap);
		});
		_sellersData.forEach((sellerData) => {
			sellerData.items.forEach((itemPerDayData) => {
				itemPerDayData.days = createSellDeltas(itemPerDayData.days);
			});
			for (let i = 0; i < allDays.length; i++) {
				sellerData.totalPerDay[i] = sellerData.items.reduce((prev, cur) => {
					if (i === 0) {
						return prev + cur.days[0].total;
					}
					return prev + cur.days[i].totalDt;
				}, 0);
			}
		});
		console.log(_sellersData);
		setSellersData(_sellersData);
		setSellerMapArr(_sellerMapArr);
	}, []);

	return (
		<div className="m-4">
			{sellersData.length > 0 && (
				<div style={{ fontWeight: "bolder" }}>
					Total so far : ${Math.round(Math.sum(sellersData.map((sd) => Math.sum(sd.totalPerDay)))).toLocaleString()}
				</div>
			)}
			{sellerMapArr.length > 0 &&
				Array.from(sellerMapArr[0]?.entries())
					.sort((a, b) => b[1].total - a[1].total)
					.map(([seller, sellerData], i) => {
						const _sellerData = sellersData.find((sellerData) => sellerData.seller === seller)!;
						return (
							<div key={`m1_${i}`}>
								<div className=" mt-8 text-blue-700">{seller}</div>
								<div className="ml-4">
									<table className="border">
										<thead>
											<tr key="tr0">
												<th key="something"></th>
												{allDays.map((day, j) => {
													return (
														<th key={`days_${j}`} colSpan={3}>
															{dates[j]}
														</th>
													);
												})}
											</tr>

											<tr key="tr1">
												<th key="something">Title</th>
												{allDays.map((day, j) => {
													return (
														<React.Fragment key={`all_days_${j}`}>
															<th key={`s_${j}`}>Sold</th>
															<th key={`p_${j}`}>Price</th>
															<th key={`t_${j}`}>Total</th>
														</React.Fragment>
													);
												})}
											</tr>
										</thead>
										<tbody>
											{sellerData.items.map((item, j) => {
												return (
													<tr key={`s_${j}`}>
														<td>
															<div
																className={`w-[600px] flex justify-start ${
																	item.title.toLowerCase().includes("liter") ? "text-red-500" : ""
																}`}
															>
																{item.title}
															</div>
														</td>
														{allDays.map((day, j) => {
															const itemPerDayData = _sellerData.items.find(
																(itemPerDayData) =>
																	itemPerDayData.title.toLowerCase() === item.title.toLowerCase()
															)!;
															const dayItem = itemPerDayData.days[j];
															return (
																<React.Fragment key={`s1_${j}`}>
																	<td>
																		<div className="w-16 flex justify-center">
																			{j === 0
																				? dayItem.sold.toLocaleString()
																				: dayItem.soldDt.toLocaleString()}
																		</div>
																	</td>
																	<td>
																		<div className="w-16 flex justify-center">{dayItem.price}</div>
																	</td>
																	<td>
																		<div className="w-16 flex justify-center">
																			$
																			{j === 0
																				? dayItem.total.toLocaleString()
																				: dayItem.totalDt.toLocaleString()}
																		</div>
																	</td>
																</React.Fragment>
															);
														})}
													</tr>
												);
											})}
											<tr key="dummy_key">
												<td>
													<div className="w-[600px] flex justify-center" />
												</td>
												{allDays.map((day, j) => {
													const totalForDay = _sellerData.totalPerDay[j];
													return (
														<React.Fragment key={`s2_${j}`}>
															<td>
																<div className="w-16 flex justify-center" />
															</td>
															<td>
																<div className="w-16 flex justify-center" />
															</td>
															<td>
																<div className="font-bold w-16 flex justify-center ">
																	${totalForDay.toLocaleString()}
																</div>
															</td>
														</React.Fragment>
													);
												})}
												<td>
													<div style={{ fontWeight: "bolder" }} className="font-bold w-16 flex justify-center" />$
													{Math.round(Math.sum(_sellerData.totalPerDay)).toLocaleString()}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						);
					})}
		</div>
	);
};

export default EbayTablePage;
